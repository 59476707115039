<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">Shartnomalar malumotlari</h3>
            </div>
          </div>

          <div class="card-body">
            <div>
              <v-tabs v-model="tab" class="wizard-step">
                <v-tab v-for="item in items" :key="item.tab">
                  {{ item.tab }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <!-- 1 -->
                <v-tab-item>
                  <General />
                </v-tab-item>
                <!-- 2 -->
                <v-tab-item>
                  <Facturas />
                </v-tab-item>
                <!-- 3 -->
                <v-tab-item>
                  <Payments />
                </v-tab-item>
                <!-- 4-->
                <v-tab-item>
                  <sverka />
                </v-tab-item>
              </v-tabs-items>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import General from './Details/General'
import Facturas from './Details/Facturas'
import Payments from './Details/Payments'
import sverka from './Details/sverka'
export default {
  components: { General, Facturas, Payments, sverka },
  name: 'ContractDetails',
  data: () => ({
    items: [
      { tab: 'Umumiy' },
      { tab: 'Hisob Faktura' },
      { tab: 'TULOVLAR' },
      { tab: 'Sverka' }
    ],
    tab: 0
  }),
  beforeCreate() {
    this.$store.dispatch('countragentKontractsPayments', this.$route.params.id)
    this.$store.dispatch('getCountragentKontracts', this.$route.params.id)
    this.$store.dispatch('countragentKontractFacturas', this.$route.params.id)
    this.$store.dispatch('countragentKontractsSverka', this.$route.params.id)
  },
  computed: {},
  methods: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kontragentlar' },
      { title: 'Kontragentlar Shartnomalari Malumotlari' }
    ])
  }
}
</script>
