<template>
  <div class="card-body">
    <div v-if="getcountragentKontractFacturas.length == 0">
      <h4 class="mb-10 font-weight-bold text-dark">
        <!-- {{getcountragentKontractFacturas}} -->
        Hisob fakturalar topilmadi
      </h4>
    </div>
    <v-data-table
      v-else
      :loading="isLoading"
      :items="contragentContractFacturas"
      :headers="headers"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      loading-text="Malumot yuklanmoqda..."
      hide-default-footer
      class="elevation-1"
      @page-count="pageCount = $event"
    >
      <template v-slot:[`item.action`]="{ item }">
        <action :index="item"></action>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="page"
        :length="Math.ceil(contragentContractFacturas.length / 5)"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import action from './faktura/action'
export default {
  components: { action },
  data: () => ({
    page: 1,

    itemsPerPage: 5,
    headers: [
      {
        text: 'ID',
        value: 'id'
      },
      {
        text: 'Sana',
        value: 'oper_date'
      },
      {
        text: 'Reg raqami',
        value: 'reg_number'
      },
      {
        text: 'Shartnoma raqami',
        value: 'contragent_contract_number'
      },
      {
        text: 'Summa',
        value: 'get_doc_products_total.total'
      },
      {
        text: 'NDS summa',
        value: 'get_doc_products_total.nds_summa'
      },
      {
        text: 'Summa(NDS)',
        value: 'get_doc_products_total.total_withnds_summa'
      },
      {
        value: 'action',
        text: ''
      }
    ]
  }),
  beforeCreate() {
    // this.$store.dispatch(
    //   "getCountragentKontractFacturas",
    //   this.$route.params.id
    // );
    // this.$store.dispatch("countragentKontractFacturas", this.$route.params.id);
  },
  computed: {
    getcountragentKontractFacturas() {
      return this.$store.getters.countragentKontractFacturas
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    contragentContractFacturas() {
      const data = this.$store.getters.countragentKontractFacturas
      data.forEach((element) => {
        if (
          element.get_doc_products_total.total !== null &&
          element.get_doc_products_total.nds_summa !== null &&
          element.get_doc_products_total.total_withnds_summa !== null
        ) {
          element.get_doc_products_total.total =
            element.get_doc_products_total.total.toLocaleString('es-US')
          element.get_doc_products_total.nds_summa =
            element.get_doc_products_total.nds_summa.toLocaleString('es-US')
          element.get_doc_products_total.total_withnds_summa =
            element.get_doc_products_total.total_withnds_summa.toLocaleString(
              'es-US'
            )
        }
      })
      return data
    }
  }
}
</script>

<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 100px;
}
</style>
