<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          :loading="isLoading"
          outlined
          dense
          label="Reg raqami"
          :value="contragentContract.reg_number"
          disabled
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          outlined
          :loading="isLoading"
          label="Shartnoma vaqti"
          :value="contragentContract.reg_date"
          disabled
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          outlined
          label="Tugash sanasi"
          :value="contragentContract.end_date"
          disabled
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          :loading="isLoading"
          outlined
          label="Summasi"
          dense
          :value="contragentContract.amount || 'Kiritilinmagan'"
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  beforeCreate() {
    // this.$store.dispatch('getCountragentKontracts', this.$route.params.id)
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    contragentContract() {
      const data = this.$store.state.requests.countragentKontracts
      // console.log(data);
      return data
    }
  }
}
</script>
