<template>
  <div>
    <div class="col-3">
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Sanagacha"
            append-icon="event"
            readonly
            dense
            outlined
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="date" scrollable>
          <v-spacer></v-spacer>
          <v-btn small color="error" @click="modal = false">Bekor qilish</v-btn>
          <v-btn
            small
            color="success"
            @click="getSverkaDate(date), $refs.dialog.save(date)"
            >OK</v-btn
          >
        </v-date-picker>
      </v-dialog>
    </div>
    <div class="input__wrapper">
      <div class="input">
        <label>Fakturalar Jami</label>
        <input
          type="text"
          disabled
          class="input-text"
          :value="getSverka.total_facturas"
        />
      </div>
      <div class="input">
        <label>Kirim Jami</label>
        <input
          type="text"
          disabled
          class="input-text"
          :value="getSverka.contract_operations_income"
        />
      </div>
      <div class="input">
        <label>Chiqim Jami</label>
        <input
          type="text"
          disabled
          class="input-text"
          :value="getSverka.contract_operations_outcome"
        />
      </div>
      <div class="input">
        <label>Qoldiq</label>
        <input
          type="text"
          disabled
          class="input-text"
          :value="getSverka.current_sverka"
        />
      </div>

      <div class="input">
        <label>Debet</label>
        <input
          type="text"
          disabled
          class="input-text"
          :value="getSverka.amountDebet"
        />
      </div>

      <div class="input">
        <label>Kredit</label>
        <input
          type="text"
          disabled
          class="input-text"
          :value="getSverka.amountKredit"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: '',

      modal: false
    }
  },
  created() {
    this.$store.dispatch('countragentKontractsSverka', this.$route.params.id)
  },
  computed: {
    getSverka() {
      return this.$store.getters.getcountragentKontractsSverka
    }
  },
  methods: {
    getSverkaDate(value) {
      const data = {
        to_date: value,
        id: this.$route.params.id
      }
      this.$store.dispatch('countragentKontractsSverkaDate', data)
    }
  }
}
</script>

<style scoped>
.v-input__prepend-outer /deep/ .v-input__icon /deep/ .v-input__icon--prepend {
  display: none !important;
}
.input__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.input label {
  display: block;
  font-size: 13px;
}
.input {
  margin-right: 6px;
  margin-bottom: 30px;
}
.input:last-child {
  margin-right: 0;
}
.input-text {
  background-color: rgb(142 190 253 / 14%);
  padding: 1rem;
  border: 1px solid rgb(142 190 253 / 28%);
  color: inherit;
  font-size: 16px;
  border-radius: 3px;
}
</style>
