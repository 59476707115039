<template>
  <div class="card-body">
    <div v-if="getPayments == 0">
      <h4 class="mb-10 font-weight-bold text-dark">
        Hisob fakturalar topilmadi
      </h4>
    </div>
    <div v-else>
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        loading-text="Malumot yuklanmoqda..."
        :items="getPayments"
        hide-default-footer
        class="elevation-1"
      >
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    headers: [
      {
        text: 'ID',
        value: 'id'
      },
      {
        text: 'Sana',
        value: 'oper_date'
      },
      {
        text: 'Debet',
        value: 'amountDebet'
      },
      {
        text: 'Kredit',
        value: 'amountKredit'
      },
      {
        text: 'Transfer raqami',
        value: 'transfer'
      },

      {
        text: 'Kontrakt',
        value: 'contract'
      },
      {
        text: 'Yaratilgan',
        value: 'created_at'
      },
      {
        text: 'Izoh',
        value: 'comment'
      },

      {
        value: 'action',
        text: ''
      }
    ]
  }),
  beforeCreate() {
    // this.$store.dispatch("countragentKontractsPayments", this.$route.params.id);
  },
  computed: {
    getPayments() {
      const data = this.$store.getters.countragentKontractsPayment
      return data
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    contragentContract() {
      const data = this.$store.state.requests.countragentKontractsPayments
      return data
    }
  }
}
</script>

<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 300px;
}
</style>
